<template>
  <el-container>

    <el-dialog v-model="showLoginModal"
               :fullscreen="false"
               :show-close="false"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               width="95%">
      <public-login></public-login>
    </el-dialog>

    <el-dialog
      v-model="showCurrentTherapyInModal"
      :fullscreen="false"
      :show-close="false"
      width="95%">
      <template #title>
        <el-row>
          <el-col :span="17">
            <h5 v-if="currentTherapy" class="text-dacy" style="word-break: break-all;">{{currentTherapy.code}}</h5>
          </el-col>
          <el-col :span="7" class="text-right">
            <el-button type="warning" @click="showCurrentTherapyInModal = false" size="small">
              <i class="el-icon el-icon-close"></i>
              Schließen
            </el-button>
          </el-col>
        </el-row>
      </template>
      <div v-if="currentTherapyLoading"><i class="el-icon el-loading-spinner"></i></div>
      <therapy v-else :is-mobile="isMobile" :current-therapy="currentTherapy" :show-therapy-protocol-study-results="showTherapyProtocolStudyResults"></therapy>
    </el-dialog>

    <el-dialog
      v-model="showTermsAcceptanceDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      lock-scroll="false"
      width="90%">

      <el-row :gutter="20">
        <el-col style="word-break: break-word;" :xs="24" :md="12">
          <h3>Haftungsausschluss</h3>
          Die Software Dacy dient der Dokumentation von Tumorbehandlungen. Die in der Software angebotenen wissenschaftlichen, therapeutischen und pharmakologischen Informationen (hier als Knowledebase präsentiert) dienen ausschließlich zur Orientierung und nicht der Therapieplanung. Trotz gründlicher Recherche kann keine Gewähr für die Vollständigkeit und Richtigkeit dieser Informationen gegeben werden. Die Benutzer der Software/Knowledgebase sind aufgefordert fehlende oder falsche Informationen der Redaktion über die <a href="https://dacy.pro/#Kontakt" target="_blank">Benachrichtigungsfunktion</a> der Software mitzuteilen. Die behandelnden Ärzte sind verpflichtet die von ihnen durchführten Behandlungen entsprechend der Leitlinien der Fachgesellschaften, der Fachinformationen und auf Grundlage des aktuellen Standes decr Wisssenschaft durchzuführen. Die Dacy GmbH schließt jegliche Haftung für die Behandlung von Patienten auf Grundlage des Fehlens oder der Fehlerhaftigkeit der hier präsentierten Informationen aus.
        </el-col>
        <el-col style="word-break: break-word;" :xs="24" :md="12">
          <h3>Datenschutzerklärung</h3>
          Diese Seite speichert keinerlei Daten von Ihnen persönlich. Keine Cookies. Keine externen Dienste. Es werden ausschließlich anonyme Protokolle auf dem Server geschrieben, die Rückschlüsse auf die generelle Verwendung dieser Seite zulassen.

          Zur Datenschutzerklärung unserer Web-Präsenz: <a href="https://dacy.pro/datenschutz/" target="_blank">https://dacy.pro/datenschutz/</a>
          <br/>
          Für statistische Zwecke werden rechnerbezogene Daten gespeichert.
          Je nach verwendetem Zugriffsprotokoll beinhaltet der Protokolldatensatz Angaben mit folgenden Inhalten:

          IP-Adresse des anfordernden Rechners,
          Datum und Uhrzeit der Anforderung,
          vom anfordernden Rechner gewünschte Zugriffsmethode / Funktion,
          vom anfordernden Rechner übermittelte Eingabewerte (z.B. Formulareinträge,
          Zugriffsstatus des Web-Servers,
          Name der angeforderten Datei (URL) sowie ggf. Netzwerkpfade, von dem aus die Datei angefordert beziehungsweise die gewünschte Funktion veranlasst wurde (Referrer).
        </el-col>
      </el-row>
      <p class="mt-4 text-center">
        <el-checkbox v-model="disclaimerAccepted" label="Ich habe den Haftungsausschluss und die Datenschutzerklärung gelesen und akzeptiert." style="white-space: normal; word-break: break-word;" size="large" border  />
      </p>
      <div class="text-center">
        <hr/>
        <el-button :disabled="!disclaimerAccepted" type="warning" @click="onSaveAcceptance">Speichern und weiter zu DACY</el-button>
      </div>

    </el-dialog>

    <el-header style="background-color:#FFF;">
      <el-row class="pt-2 pb-2">
        <el-col :xs="18" :lg="18">
          <h3 class="mt-2">Knowledgebase</h3>
        </el-col>
        <el-col :xs="6" :lg="6" class="text-right align-content-end">
          <public-main-menu></public-main-menu>
        </el-col>
      </el-row>
    </el-header>
    <div class="w-100" style="background-color:#f39200; height:8px;"></div>
    <el-main>

      <el-card class="box-card" v-if="viewIsReady">

        <el-row :gutter="20">
          <el-col :lg="6" :xs="24">
            <h6 class="mb-3">Diagnose</h6>
            <h5 v-if="currentDiagnosis">{{currentDiagnosis.title}}</h5>
            <span v-else><i class="el-icon el-icon-loading" style="font-size:1.5em;"></i></span>
          </el-col>
          <el-col :lg="7" :xs="24" class="mb-3">
            <h6>Behandlungssituation / Therapiemodalität</h6>
            <el-select size="medium" class="w-100" v-model="form.treatmentsituation" filterable :disabled="!viewIsReady">
              <el-option :value="null" label="-- Keine Auswahl --"></el-option>
              <el-option
                v-for="item in allTreatmentSituations"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :lg="7" :xs="24" class="mb-3">
            <h6>Prädiktive Faktoren</h6>
            <el-select size="medium" class="w-100" v-model="form.biomarker" filterable :disabled="!viewIsReady">
              <el-option :value="null" label="-- Keine Auswahl --"></el-option>
              <el-option
                v-for="item in allBiomarker"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :lg="4" :xs="24">
            <h6>Zulassung</h6>
            <el-select size="medium" class="w-100" v-model="form.status" filterable :disabled="!viewIsReady">
              <el-option :value="null" label="-- Keine Auswahl --"></el-option>
              <el-option
                v-for="item in allStatusOptions"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>


        <hr/>
        <h6>Therapieprinzip auswählen</h6>

        <el-row :gutter="20">
          <el-col :lg="6" :xs="24" class="round">

            <i v-if="!currentDiagnosis" class="el-icon el-icon-loading" style="font-size:1.5em;"></i>
            <el-table
              v-else
              :data="therapies"
              style="width: 100%"
              class="mb-3"
              :show-header="false"
              highlight-current-row
              @row-click="onTherapyRowClick"
              :height="(isMobile ? undefined : '60vh')"
              stripe>
              <el-table-column>
                <template #default="scope">
                  {{ scope.row.code }}

                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="inlabel"
                    placement="top"
                    v-if="therapyInInlabel(scope.row, 1)"
                  >
                    <el-tag  type="success" size="mini">In</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="offlabel"
                    placement="top"
                    v-if="therapyInInlabel(scope.row, 0)"
                  >
                    <el-tag  type="danger" size="mini">off</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Experimentell"
                    placement="top"
                    v-if="therapyInInlabel(scope.row, 2)"
                  >
                    <el-tag type="warning" size="mini">ex</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Zulassung erloschen"
                    placement="top"
                    v-if="therapyInInlabel(scope.row, 3)"
                  >
                    <el-tag type="danger"  size="mini" style="text-decoration:line-through;" >in</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Offlabel durch G-BA erlaubt"
                    placement="top"
                    v-if="therapyInInlabel(scope.row, 5)"
                  >
                    <i class="ml-1 text-danger el-icon el-icon-circle-close"></i>
                  </el-tooltip>

                  <el-tooltip v-if="therapyHasSurveys(scope.row)" placement="top">
                    <template #content>
                      <div :key="survey.id" v-for="survey of surveysOfTherapy(scope.row)">{{survey.title_short}}</div>
                    </template>
                    <el-tag size="mini">S</el-tag>
                  </el-tooltip>

                </template>
              </el-table-column>
            </el-table>

          </el-col>
          <el-col :lg="18" :xs="24" v-if="!currentTherapy" v-loading="currentTherapyLoading">
          </el-col>
          <el-col :lg="18" :xs="24" v-if="!isMobile && currentTherapy">
            <therapy :current-therapy="currentTherapy" :show-therapy-protocol-study-results="showTherapyProtocolStudyResults"></therapy>
          </el-col>
        </el-row>

      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";
import axios from "axios";
import { therapyNotInGER, openBrowser, surveysOfTherapyProtocol } from "./lib/reusable.js"
import Therapy from "./components/parts/Therapy.vue";
import PublicLogin from "./PublicLogin.vue";
import PublicMainMenu from "./components/parts/PublicMainMenu.vue";
const mobile = require('is-mobile');

export default {
  name: "PublicKnowledgebase",
  props: [],
  components: {
    PublicMainMenu,
    PublicLogin,
    Therapy,
  },
  data () {
    return {
      viewIsReady: false,
      currentTherapy: null,
      currentTherapyLoading: false,
      currentTherapyProtocol: null,
      currentReferer: null,
      currentDiagnosis: null,
      allTreatmentSituations: [],
      allBiomarker: [],
      filterTherapyProtocolIds: null,
      showTermsAcceptanceDialog: false,
      showCurrentTherapyInModal: false,
      showLoginModal: false,
      disclaimerAccepted: false,
      showTherapyProtocolStudyResults: true,
      isMobile: mobile(),
      allStatusOptions: [
        { title: 'Nur inlabel', obsolet: 0, inLabelIds: [1], id: 1 },
        { title: 'Inlabel und Offlabel', obsolet: 0, inLabelIds: [0, 1, 5], id: 2 },
        { title: 'Alle', obsolet: null, inLabelIds: [], id: 3 }
      ],
      form: {
        therapies: [],
        diagnosis: null,
        treatmentsituation: null,
        biomarker: null,
        only_protocol_own: false,
        public: true,
        only_active: true,
        status: null
      },
    }
  },
  mounted: async function () {

    if(!localStorage.getItem('dacy-token')){
      this.showLoginModal = true
    }
    else if(!localStorage.getItem("dacy-disclaimer-accepted")) {
      this.showTermsAcceptanceDialog = true
    }
    else {
      await this.init()
    }

  },
  computed: {
    ...mapGetters('diagnosis', ['publicDiagnosis']),
    ...mapState('therapies', ['therapies']),
    ...mapState('diagnosis', ['publicDiagnosis']),
    ...mapState('biomarker', ['publicBiomarker']),
    ...mapState('treatmentsituations', ['publicTreatmentsituations']),
    filteredDiagnosis() {

      if(this.diagnosisSearch !== '') {
        const search = this.diagnosisSearch.toLowerCase()
        let filtered = []
        for(const diag of this.publicDiagnosis) {
          if(diag.code.toLowerCase().indexOf(search) !== -1 || diag.title.toLowerCase().indexOf(search) !== -1) {
            filtered.push(diag)
          }
        }
        return filtered
      }

      return this.publicDiagnosis
    }
  },
  watch: {
    'form.diagnosis': async function () {
      await this.getTherapies(this.form)
      this.filterTreatmentSituations()
      this.filterBiomarker()
    },
    'form.treatmentsituation': async function () {
        this.form.biomarker = null
        await this.getTherapies({
          ...this.form,
          status: this.allStatusOptions.find(status => status.id === this.form.status)
        })
        this.filterBiomarker()
    },
    'form.biomarker':async function () {
      await this.getTherapies({
        ...this.form,
        status: this.allStatusOptions.find(status => status.id === this.form.status)
      })
      if(this.currentTherapy && (this.form.diagnosis == 3 || this.form.diagnosis == 13)) {
        this.onTherapyRowClick(this.currentTherapy)
      }
    },
    'form.status':async function () {
      await this.getTherapies({
        ...this.form,
        status: this.allStatusOptions.find(status => status.id === this.form.status)
      })
    }
  },
  methods: {
    ...mapActions('diagnosis', ['getPublicDiagnosis']),
    ...mapActions('biomarker', ['getPublicBiomarker']),
    ...mapActions('treatmentsituations', ['getPublicTreatmentsituations']),
    ...mapActions('therapies', ['getTherapies']),
    therapyNotInGER,
    openBrowser,
    surveysOfTherapyProtocol,
    async init() {

      this.viewIsReady = false

      await this.getPublicDiagnosis()
      await this.getPublicBiomarker()
      await this.getPublicTreatmentsituations()

      if(this.$route.query && this.$route.query.d) {
        const exist = _.find(this.publicDiagnosis, (d) => d.id == this.$route.query.d)
        if(exist) {
          this.currentDiagnosis = exist
          this.form.diagnosis = this.$route.query.d
        }
      }

      this.viewIsReady = true

      // await this.getTherapies(this.form)
      // this.allTreatmentSituations = {...this.publicTreatmentsituations}
      // this.allBiomarker = {...this.publicBiomarker}

      this.saveNewAccess({
        event: 'enter'
      })

    },
    filterTreatmentSituations() {
      let filteredTreatmentSituations = []
      let addedTreatmentSituations = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(therapy.treatmentSituation && addedTreatmentSituations.indexOf(therapy.treatmentSituation.id) === -1) {
          addedTreatmentSituations.push(therapy.treatmentSituation.id)
          filteredTreatmentSituations.push(therapy.treatmentSituation)
        }

      }
      filteredTreatmentSituations = _.sortBy(filteredTreatmentSituations, [function(o) { return o.title.toLowerCase(); }])
      this.allTreatmentSituations = filteredTreatmentSituations

    },
    filterBiomarker() {
      let filteredBiomarker = []
      let addedBiomarker = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(this.form.diagnosis == 3 || this.form.diagnosis == 13) {

          if(therapy.therapyProtocols && therapy.therapyProtocols.length) {
            for(const therapyProtocol of therapy.therapyProtocols) {
              for(const biomarker of therapyProtocol.biomarker) {
                if(addedBiomarker.indexOf(biomarker.id) === -1) {
                  addedBiomarker.push(biomarker.id)
                  filteredBiomarker.push(biomarker)
                }
              }
            }
          }

        }
        else {

          if(therapy.biomarker && therapy.biomarker.length) {
            for(const biomarker of therapy.biomarker) {
              if(addedBiomarker.indexOf(biomarker.id) === -1) {
                addedBiomarker.push(biomarker.id)
                filteredBiomarker.push(biomarker)
              }
            }
          }

        }



      }
      filteredBiomarker = _.sortBy(filteredBiomarker, [function(o) { return o.title.toLowerCase(); }])
      this.allBiomarker = filteredBiomarker

    },
    async onTherapyRowClick(row) {
      this.currentTherapy = null
      this.currentTherapyLoading = true

      if(this.isMobile) this.showCurrentTherapyInModal = true

      const result = await axios.get('/public/therapies/' + row.id)
      if(result.status === 404) {
        this.$notify.error({
          message: "Therapie wurde nicht gefunden",
          position: 'bottom-left'
        });
      }
      else {

        this.saveNewAccess({
          event: 'therapy',
          event_meta_id: row.id
        })

        this.currentTherapy = result.data
        if(this.filterTherapyProtocolIds && this.filterTherapyProtocolIds.length && this.filterTherapyProtocolIds.length > 0) {
          let newTherapyProtocols = []
          for(const therapyProtocol of this.currentTherapy.therapyProtocols) {
            if(this.filterTherapyProtocolIds.indexOf(therapyProtocol.id) !== -1) {
              newTherapyProtocols.push(therapyProtocol)
            }
          }

          this.currentTherapy.therapyProtocols = newTherapyProtocols
        }

        console.log(this.form)
        if((this.form.diagnosis == 3 || this.form.diagnosis == 13) && this.form.biomarker) {
          let newTherapyProtocols = []
          for(const therapyProtocol of this.currentTherapy.therapyProtocols) {
            if(_.find(therapyProtocol.biomarker, (b) => b.id === this.form.biomarker)) {
              newTherapyProtocols.push(therapyProtocol)
            }
          }
          this.currentTherapy.therapyProtocols = newTherapyProtocols
        }

      }
      this.currentTherapy.therapyProtocols = _.sortBy(this.currentTherapy.therapyProtocols, [function(o) { return (o.meta && o.meta.protocolown ? o.meta.protocolown.title.toLowerCase() : o.protocol.title.toLowerCase()) }])
      this.currentTherapyLoading = false
    },
    therapyInInlabel(therapy, needle) {
      const groups = _.groupBy(therapy.therapyProtocols, 'inlabel')
      return !!groups[needle]
    },
    therapyHasSurveys(therapy) {
      if(!therapy.therapyProtocols) return false
      return _.find(therapy.therapyProtocols, (p) => (p.meta && p.meta.surveys && p.meta.surveys.length > 0))
    },
    surveysOfTherapy(therapy) {
      if(!therapy.therapyProtocols) return []
      let therapySurveys = []
      for(const p of therapy.therapyProtocols) {
        if(p.meta && p.meta.surveys && p.meta.surveys.length > 0) {
          for(const tp of p.meta.surveys) {
            therapySurveys.push(tp.survey)
          }
        }
      }
      return therapySurveys
    },
    async onSaveAcceptance() {
      localStorage.setItem("dacy-disclaimer-accepted", true)
      localStorage.setItem("dacy-client", Math.floor(Math.random() * 10000000000000001))
      this.showTermsAcceptanceDialog = false
      await this.init()
    },
    async saveNewAccess(meta) {
      await axios.post('/access/', {
        client: localStorage.getItem('dacy-client'),
        scope: 'kbpublic',
        scope_meta: 'diagnosis',
        scope_meta_id: this.$route.query.d,
        event: meta.event ? meta.event : undefined,
        event_meta_id: meta.event_meta_id ? meta.event_meta_id : undefined
      })
    }
  }
}
</script>
<style>
</style>
